import React from 'react';
import { css } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import { theme } from '../../../Theme';
import iconSelector from '../../IconSelector';
import MaybeLink from '../../MaybeLink';

const iconCardCSS = css`
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.space[3]} 0;
    border-radius: ${theme.commonStyles.borderRadius};
    background: ${theme.colors.white};
    font-weight: 700;
    text-decoration: none;
    white-space: nowrap;
    transition: ${theme.transitions.boxShadow};

    &:hover {
      box-shadow: ${theme.commonStyles.boxShadow};
    }
  }

  &__icon-wrapper {
    margin-right: ${theme.space[2]};
  }

  &__icon {
    min-width: 32px;
    min-height: 28px;
  }
`;

export const iconCardsLayoutCSS = css`
  ${theme.below.md} {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;

    && {
      column-gap: ${theme.space[2]};
      row-gap: ${theme.space[2]};
    }
  }
`;

const baseCSS = iconCardCSS;

const IconCard = props => {
  const { text, link, icon } = getPropsOrFieldValues(props);

  const Icon = iconSelector(icon);

  return (
    <div className={baseCSS}>
      <MaybeLink
        className={`${baseCSS}__inner`}
        link={link}
        elementFallback="span"
      >
        {Icon && (
          <span className={`${baseCSS}__icon-wrapper`}>
            <Icon className={`${baseCSS}__icon`} />
          </span>
        )}
        {text}
      </MaybeLink>
    </div>
  );
};

export default IconCard;
