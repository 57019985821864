import React from 'react';
import { useQuery } from 'react-apollo';
import { theme } from '../../../Theme';
import BlogPostsQuery from '../../Blog/Queries/BlogPosts.gql';
import BlogPostCard from '../../Blog/BlogPostCard';
import { sectionTitleCSS } from '../../ThemeClasses';
import Layout, { spliceLayout } from '../Components/Layout';

const PinnedPostsLayout = props => {
  const [children, layoutProps] = spliceLayout(props);

  const blogPostPaths = children
    ?.map(child => child?.props?.link?.value)
    .filter(Boolean);

  if (!layoutProps) return null;

  const title = props?.title?.value;

  return (
    <>
      {title && (
        <Layout container={layoutProps.container}>
          <div
            className={sectionTitleCSS}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Layout>
      )}
      <Layout {...layoutProps}>
        <PinnedPosts blogPostPaths={blogPostPaths} />
      </Layout>
    </>
  );
};

const PinnedPosts = ({ blogPostPaths }) => {
  const {
    data
    // loading, // TODO - Add loading state
    // error
  } = useQuery(BlogPostsQuery, {
    variables: { id: theme.pageIds.magazine },
    skip: !blogPostPaths
  });

  const subpages = data?.page?.subPages;

  const posts = React.useMemo(() => {
    const tempPosts = [];

    if (subpages?.length > 0) {
      const allBlogPosts = subpages?.reduce((acc, category) => {
        if (category?.subPages?.length > 0) {
          category.subPages.forEach(post => {
            acc.push(post);
          });
        }

        return acc;
      }, []);

      if (allBlogPosts.length > 0) {
        blogPostPaths.forEach(path => {
          const post = allBlogPosts.find(
            post => post?.primaryRoute?.path === path
          );

          if (post) {
            tempPosts.push(post);
          }
        });
      }
    }

    return tempPosts;
  }, [blogPostPaths, subpages]);

  if (!posts?.length > 0) return null;

  return (
    <>
      {posts.map((post, i) => (
        <BlogPostCard key={i} post={post} />
      ))}
    </>
  );
};

export default PinnedPostsLayout;
