import { ReactComponent as CircledPercentage } from '../../svg/CircledPercentage.svg';
import { ReactComponent as Clothing } from '../../svg/Clothing.svg';
import { ReactComponent as Finn } from '../../svg/Finn.svg';
import { ReactComponent as FishingRod } from '../../svg/FishingRod.svg';
import { ReactComponent as Rifle } from '../../svg/Rifle.svg';
import { ReactComponent as Tag } from '../../svg/Tag.svg';
import { ReactComponent as Tent } from '../../svg/Tent.svg';

const iconSelector = icon =>
  ({
    discount: CircledPercentage,
    clothing: Clothing,
    finn: Finn,
    fishing: FishingRod,
    rifle: Rifle,
    tag: Tag,
    tent: Tent
  }[icon] || null);

export default iconSelector;
