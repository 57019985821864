import React from 'react';
import { css, cx } from 'linaria';
import getComponentProps from '../../Utils/getComponentProps';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import Content, { componentKeyContent } from '../Components/Content';
import Layout, { componentKeyLayout } from '../Components/Layout';
import Media, { componentKeyMedia } from '../Components/Media';
import { theme } from '../../../Theme';

const sideBySideCSS = css`
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${theme.space[4]} ${theme.space[2]};
    background: ${theme.colors.white};
  }

  ${theme.above.md} {
    flex-direction: row;

    &--reverse-order {
      flex-direction: row-reverse;
    }

    &__content {
      height: 100%;
    }
  }
`;

const baseCSS = sideBySideCSS;

const SideBySide = props => {
  const [layoutProps, mediaProps, contentProps] = getComponentProps(
    [componentKeyLayout, componentKeyMedia, componentKeyContent],
    props?.children
  );

  const { reversedOrder } = getPropsOrFieldValues(props);

  return (
    <Layout
      {...layoutProps}
      className={cx(
        baseCSS,
        reversedOrder ? `${baseCSS}--reverse-order` : null
      )}
    >
      <Media {...mediaProps} className={baseCSS} />
      <Content {...contentProps} className={baseCSS} buttonArrowMD={true} />
    </Layout>
  );
};

export default SideBySide;
