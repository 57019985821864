import React from 'react';
import { css } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import Layout from '../Components/Layout';
import { theme } from '../../../Theme';
import { getBoxLabelByNiceName } from '../../HelloRetail/HelloRetail';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import ProductSlider from '../../ProductSlider/ProductSlider';
import { sectionTitleCSS } from '../../ThemeClasses';

const helloRetailCSS = css`
  /* padding: 0 1rem; */
  /* flex-flow: row wrap;

  .layout-child {
    width: 100%;
  }

  ${theme.above.md} {
    padding: 0 2.5rem;
  } */
`;

const spliceComponentFromChildren = (children, componentKey) => {
  const componentIndex = children.findIndex(child =>
    child?.key.includes(componentKey)
  );

  children.splice(componentIndex, 1);

  return children;
};

const HelloRetailLayout = props => {
  const children = props?.children;
  const { loggedIn } = useAuth();

  if (!children?.length > 0) return null;

  const { helloRetailLabel, title } = getPropsOrFieldValues(props);

  const layoutProps = {
    ...children.find(child => child?.key.indexOf('component-layout') !== -1)
      ?.props
  };

  const boxKeyLabel = getBoxLabelByNiceName(helloRetailLabel);

  const { helloRetailBoxes } = props;

  const products = helloRetailBoxes?.boxes?.[boxKeyLabel];

  if (!products) return null;

  // Disable gap for HelloRetailSection
  layoutProps.gap = null;

  spliceComponentFromChildren(children, 'component-layout');

  return (
    <>
      {title ? (
        <Layout
          className={helloRetailCSS}
          container={layoutProps?.container}
          margin={layoutProps?.margin}
        >
          <div
            className={sectionTitleCSS}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Layout>
      ) : null}

      <Layout margin={layoutProps?.margin}>
        <ProductSlider
          products={products}
          imageSizes={[1 / 3, 1 / 3, 1 / 6]}
          loggedIn={loggedIn}
        />
      </Layout>
    </>
  );
};

export default HelloRetailLayout;
