import React from 'react';
import { css, cx } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import ContentPageLink from '@jetshop/ui/ContentPageLink';
import { paddingForAspectRatio } from '@jetshop/ui/Image/utils/paddingForAspectRatio';
import Logo from '../../Theme/JaktOgFriluftLogo.svg';
import { theme } from '../../Theme';

export const postCategoryLinkCSS = css`
  display: inline-block;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-decoration: none;
  color: currentColor;
`;

const postCardCSS = css`
  color: ${theme.colors.black};

  a {
    text-decoration: none;
  }

  &__category-link {
    margin-top: ${theme.space[2]};
  }

  &__title {
    margin-top: 0.625rem;
    font-size: 1.25rem;
    line-height: 1.25;
  }

  &__content {
    margin-top: 0.75rem;
  }

  &__fallback-image-container {
    position: relative;
  }

  &__fallback-image {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const baseCSS = postCardCSS;

export const imageAspectString = '83:54';

const BlogPostCard = ({ post }) => {
  const postImage = post?.images?.[post.images?.length - 1]?.url;

  return (
    <div className={baseCSS}>
      <ContentPageLink page={post}>
        {postImage ? (
          <Image
            aspect={imageAspectString}
            cover={true}
            sizes={[1, 1, 1 / 3, 1 / 2]}
            src={postImage}
            alt={post.name}
            quality={80}
          />
        ) : (
          <div
            className={`${baseCSS}__fallback-image-container`}
            style={{ paddingBottom: paddingForAspectRatio(imageAspectString) }}
          >
            <img
              className={`${baseCSS}__fallback-image`}
              src={Logo}
              alt={theme.storeName}
            />
          </div>
        )}
      </ContentPageLink>
      {post?.parent?.primaryRoute && (
        <ContentPageLink
          className={cx(`${baseCSS}__category-link`, postCategoryLinkCSS)}
          page={post.parent}
        >
          {post.parent.name}
        </ContentPageLink>
      )}
      <ContentPageLink page={post}>
        {post?.name && <h2 className={`${baseCSS}__title`}>{post.name}</h2>}
      </ContentPageLink>
    </div>
  );
};

export default BlogPostCard;
