import SideBySide from './Root/SideBySide';
import IconedCards from './Root/IconedCards';
import shared from './shared';
import IconCard from './Components/IconCard';
import PinnedPosts from './Root/PinnedPosts';

export const blocksStartpage = {
  ...shared,
  'COMPONENT-ICON-CARD': IconCard,
  'ICONED CARDS': IconedCards,
  'SIDE-BY-SIDE': SideBySide,
  'PINNED POSTS': PinnedPosts,
  'COMPONENT-POST': () => null
};

export default blocksStartpage;
