import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import blocksStartpage from '../Theme/ContentEditor/blocksStartpage';
import { useLipscoreLoader } from '../Theme/Lipscore';
import LoadingPage from '../LoadingPage';
import { theme } from '../Theme';
import startPageQuery from './StartPageQuery.gql';
import HelloRetailLayout from '../Theme/ContentEditor/Root/HelloRetailLayout.js';
import HelloRetailFetcher, {
  getBoxLabelByNiceName
} from '../Theme/HelloRetail/HelloRetail';
import getPropsOrFieldValues from '../Theme/Utils/getPropsOrFieldValues';

const StartPageWrapper = styled('div')`
  min-height: 30vh;
  position: relative;
  margin-top: -${theme.elementSizes.pagePaddingTop};

  &:before {
    content: '';
    height: 140px;
    width: 100%;
    position: absolute;
    top: 0;

    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.12) 21%,
      rgba(0, 0, 0, 0.25) 30%,
      rgba(0, 0, 0, 0.36) 39%,
      rgba(0, 0, 0, 0.48) 46%,
      rgba(0, 0, 0, 0.59) 54%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: 1;
  }
`;

const MaybeHelloRetailFetcher = ({ children, helloRetailItems }) => {
  const startpageBoxes = helloRetailItems.map(item => {
    const helloRetailLabelPropery = item?.properties?.find(
      prop => prop.name === 'helloRetailLabel'
    );

    const boxLabelNiceName = getPropsOrFieldValues(helloRetailLabelPropery)
      ?.value;

    return getBoxLabelByNiceName(boxLabelNiceName);
  });

  return (
    <HelloRetailFetcher boxKeyLabels={startpageBoxes}>
      {boxes => <>{children(boxes)}</>}
    </HelloRetailFetcher>
  );
};

const StartPage = ({ startPageId }) => {
  useLipscoreLoader();

  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });
  const { loading } = result;

  if (loading || !result?.data) return <LoadingPage />;

  const helloRetailContentEditorBlockKey = 'Hello Retail Section';

  const maybeHelloRetailItems = result?.data?.startPage?.data?.items?.filter(
    item => item?.type === helloRetailContentEditorBlockKey
  );

  return (
    <StartPageWrapper>
      <MaybeHelloRetailFetcher helloRetailItems={maybeHelloRetailItems}>
        {boxes => {
          return (
            <StartPageRenderer
              result={result}
              startPageComponents={{
                ...blocksStartpage,
                ...(boxes
                  ? {
                      [helloRetailContentEditorBlockKey]: props => (
                        <HelloRetailLayout
                          helloRetailBoxes={boxes}
                          {...props}
                        />
                      )
                    }
                  : {})
              }}
            />
          );
        }}
      </MaybeHelloRetailFetcher>
    </StartPageWrapper>
  );
};

export default StartPage;
